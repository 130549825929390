import { FC } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';

import cx from 'classnames';

import Card, { CardProps } from 'components/Card/Card';
import { ILotteryFAQ } from 'models/ILotteryFAQ';

import s from './FAQAccordionItem.module.scss';

interface IProps extends CardProps {
  faq: ILotteryFAQ;
  active?: boolean;
}

const FAQAccordionItem: FC<IProps> = ({ faq, active, className, ...props }) => {
  return (
    <Card
      className={cx(className, 'border no-shadow', {
        'border-primary': !active,
        'border-danger': active,
      })}
      {...props}
    >
      <Card.Header displaySeparator={false} className="pt-2">
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={faq.id.toString()}
          className="px-0 d-flex outline-none justify-content-between w-100 align-items-center pb-3"
        >
          <h3 className="text-left m-0 large-body">{faq.question}</h3>
          <FaChevronDown
            color="var(--dark-grey)"
            className={cx(s.chevron, { [s.rotateChevron]: active })}
          />
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={faq.id.toString()}>
        <Card.Body className="pt-0">
          <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default FAQAccordionItem;

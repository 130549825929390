import { FC, useState } from 'react';
import { Accordion, AccordionProps } from 'react-bootstrap';

import FAQAccordionItem from 'components/FAQAccordionItem/FAQAccordionItem';
import { ILotteryFAQ } from 'models/ILotteryFAQ';

interface IProps extends AccordionProps {
  faqs: ILotteryFAQ[];
}

const FAQAccordion: FC<IProps> = ({ faqs, defaultActiveKey, ...props }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  return (
    <Accordion
      className="accordion-with-border-radius mb-n3"
      activeKey={activeKey}
      onSelect={key => {
        setActiveKey(key === null ? undefined : key);
      }}
      {...props}
    >
      {(faqs || []).map(faq => (
        <FAQAccordionItem
          active={faq.id.toString() === activeKey}
          key={faq.id}
          faq={faq}
          className="mb-3"
        />
      ))}
    </Accordion>
  );
};

export default FAQAccordion;
